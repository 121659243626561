import React from 'react'
import Card from './Card'

import { cardContainer } from "../styles/cardContainer.module.css"

export default function CardContainer(props) {
    return (
        <div className={cardContainer}>
            {props.cardArray.map((card) => <Card key={card.url} url={card.url} friendlyUrl={card.friendlyUrl} header={card.header} text={card.text}/>)}
        </div>
    )
}
