import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import CardContainer from '../components/CardContainer';
import HeadWithPara from '../components/HeadWithPara';
import Layout from '../components/Layout';

const lommebokData = require('../../static/json/lommeboker.json')

export default class lommeboker extends Component {
    render() {
        const wallets = lommebokData.map((lommebok) => {
            return (
                <div key={lommebok.header}>
                    <HeadWithPara headerText={lommebok.header} paragraphText={lommebok.text} />
                    <CardContainer cardArray={lommebok.wallets} />
                </div>
            )
        });
        return (
            <Layout>
                <div className="Lommeboker">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Finn den rette lommeboken for deg - Bitcoin Cash Norge</title>
                        <meta name="description" content="Velg den rette lommebok for Bitcoin Cash. Tilgjengelige lommebøker er: Electron Cash, online lommebok, lommebok app, hardware wallet og papir lommebok." />
                        <link rel="canonical" href="https://bitcoincashnorge.no/lommeboker" />
                    </Helmet>
                    <div className="container" >
                        <h1>Bitcoin Cash lommebøker</h1>
                        <p>Finn en lommebok som passer ditt bruk. Lommebøker brukes til å oppbevare dine Bitcoin Cash. Det anbefales å velge en lommebok der du kan hente ut din private nøkkel, slik at du kan importere lommeboken i en annen klient om nettsiden eller applikasjonen skulle forsvinne.</p>
                        {wallets}
                    </div>
                </div>
            </Layout>
        );
    }
}
