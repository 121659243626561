import React from "react"

import { button } from "../styles/button.module.css"


export default function Button(props) {
    return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a className={button} style={{ backgroundColor: props.color, color: props.textColor }} href={props.url} target="_blank">
            Gå til {props.friendlyUrl}
        </a>
    );
}

Button.defaultProps = {
    color: '#0AC18E',
    textColor: 'black'
}