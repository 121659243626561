import React from 'react'
import Button from './Button'

import { card } from "../styles/card.module.css"

export default function Card(props) {
    return (
        <div className={card} style={{ borderColor: props.color, color: props.textColor }}>
            <h3>{props.header}</h3>
            <p>{props.text}</p>
            <div className="TakeSpace"></div>
            <Button url={props.url} friendlyUrl={props.friendlyUrl}/>
        </div>
    )
}

Card.defaultProps = {
    color: '#0AC18E',
    textColor: 'white'
}